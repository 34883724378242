
const namespace = 'auth';

import { Component, Vue } from 'vue-property-decorator'

// services
import { clearToken } from '@/services/api'

//utils
import { isEmpty } from '@/utils';

//Vuex
import { Action, Getter } from 'vuex-class';

//molds
import { Form, Input } from '@/components/wrappers/molds/Form';

//components
import PublicContent from '@/components/publicContent/PublicContent.vue'

//classes
import { Alert } from '@/services/notification'

//wrappers
import { WForm, WButton, WSubtitle, Box, Loader, SizedBox } from '@/components/wrappers';

@Component({
	components: {
		PublicContent,
		SizedBox,
		WForm,
		WButton,
		WSubtitle,
		Box, Loader,
	}
})

export default class Login extends Vue {

	@Action('auth', { namespace }) dispathLogin: any;
	@Getter('error', { namespace }) loginError!: any;

	alert = new Alert(this.$store)

	isLoading = false

	dispatchForm(dataForm: Input[]): void {
		this.isLoading = true
		clearToken();
		this.dispathLogin(dataForm).then(() => {
			if (this.loginError == false) {
				// this.alert.success("Cidadão reconhecido")
				this.$router.push('/search')
			} else if (this.loginError == 403) {
				this.alert.danger("Credeciais inválidas")
				this.isLoading = false
			} 
			// else if (this.loginError == 5000) {
			// 	this.alert.danger("Desculpe ocorreu um erro: 5000")
			// 	this.isLoading = false
			// } 
			else {
				this.alert.apiMessage("Erro Inesperado! :(", this.loginError, "danger")
				this.isLoading = false
			}			
		})
	}

	get route(): string {
		if(!isEmpty(`${this.$route.query.template}`)){
			this.setTemplate(`${this.$route.query.template}`)
		}
		return "register"
	}

	setTemplate(template: string): void {
		this.$store.dispatch("template/setTemplate", template)
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			grid: [
				[
					{
						fieldName: "name",
						label: "Nome",
						type: "text",
						maxlength: 100,
						placeholder: "nome",
						required: true,
						value: ""
					}
				],
				[
					{
						fieldName: "document",
						label: "CPF",
						type: "text",
						maxlength: 50,
						placeholder: "documento",
						required: true,
						value:""
					}
				],
				[
					{
						fieldName: "birthDate",
						label: "Data de Nascimento",
						type: "date",
						maxlength: 10,
						required: true,
						value: ""
					}
				],
			]
		}
	}

}
